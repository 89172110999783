import { Suspense, lazy, useState, useEffect, useMemo } from "react";
import { Field, FormSection } from "@atlaskit/form";
import Comment from "@atlaskit/comment";
import { ReactRenderer } from "@atlaskit/renderer";
import Avatar from "@atlaskit/avatar";

const IssueCommentEditor = lazy(
  () => import("../IssueCommentEditor/IssueCommentEditor")
);

const fetchComments = async (issueId: string) => {
  const response = await fetch(
    `${process.env.API_BASE}/issues/${issueId}/comments`
  );

  if (response.ok) {
    return response.json();
  } else {
    console.log(response.status, response.statusText, response.text());
    return [];
  }
};

export const IssueComments = ({ issueId }: { issueId: string }) => {
  const [comments, setComments] = useState<any[]>([]);

  const isDisabled = useMemo(() => {
    const params = new URLSearchParams(window.location.search);

    return params.get("comments") === "disabled";
  }, []);

  useEffect(() => {
    if (isDisabled) {
      return;
    }
    fetchComments(issueId).then(({ comments }) => setComments(comments));
  }, []);

  const onAddComment = (comment: any) => {
    setComments([...comments, comment]);
  };

  if (isDisabled) {
    return null;
  }

  return (
    <>
      {comments.length > 0 && (
        <FormSection>
          <Field name="comments" label="Comments">
            {() =>
              comments.map((comment) => (
                <Comment
                  key={comment.id}
                  avatar={<Avatar src={comment.user.photoURL} />}
                  author={comment.user.displayName}
                  content={
                    <ReactRenderer
                      document={{ ...JSON.parse(comment.comment), version: 1 }}
                    />
                  }
                />
              ))
            }
          </Field>
        </FormSection>
      )}
      <Suspense>
        <IssueCommentEditor issueId={issueId} onAddComment={onAddComment} />
      </Suspense>
    </>
  );
};
